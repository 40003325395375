<template>
    <v-container fluid>
        <v-dialog max-width="400px" v-model="quantityDialog">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-card-title>{{ $t("bill.uniteQuantity") }}</v-card-title>
                </v-toolbar>
                <v-container fluid
                    ><v-card-text>
                        <v-text-field
                            outlined
                            type="number"
                            min="0"
                            dense
                            v-model="unitedQuantity"
                            :label="$t('billBody.quantity')"
                            hide-details
                        ></v-text-field> </v-card-text
                ></v-container>
                <v-card-actions>
                    <v-btn @click="uniteQuantity" color="primary">
                        <v-icon>mdi-pencil</v-icon>
                        {{ $t("edit") }}</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn color="red" @click="quantityDialog = false" text>{{
                        $t("cancel")
                    }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Save-->
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-card :loading="loading">
                <Page-Header
                    :title="$t('billTypes.' + bType)"
                    icon="mdi-cash"
                ></Page-Header>
                <v-card-title>
                    <v-row>
                        <!----------------Tabs Names--------------------->
                        <v-col cols="12" lg="8" ms="8" sm="8">
                            <v-tabs v-model="tab" align-with-title>
                                <v-tabs-slider color=""></v-tabs-slider>

                                <v-tab v-for="item in tabsNames" :key="item">
                                    {{ item }}
                                </v-tab>
                            </v-tabs>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-tabs-items v-model="tab">
                    <!----------------Main Info. Tab--------------------->
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" md="3">
                                        <v-text-field
                                            outlined
                                            readonly
                                            type="number"
                                            dense
                                            v-model="editedItem.billNumber"
                                            :label="$t('bill.billNumber')"
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <template>
                                            <div>
                                                <v-menu
                                                    ref="menu1"
                                                    v-model="menu"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-text-field
                                                            hide-details
                                                            outlined
                                                            dense
                                                            :rules="rules"
                                                            v-model="
                                                                editedItem.billDate
                                                            "
                                                            :label="
                                                                $t(
                                                                    'bill.billDate'
                                                                )
                                                            "
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="
                                                            editedItem.billDate
                                                        "
                                                        :active-picker.sync="
                                                            activePicker1
                                                        "
                                                        :max="
                                                            new Date(
                                                                Date.now() -
                                                                    new Date().getTimezoneOffset() *
                                                                        60000
                                                            )
                                                                .toISOString()
                                                                .substr(0, 10)
                                                        "
                                                        min="1950-01-01"
                                                        @change="saveDate1"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                        </template>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-select
                                            outlined
                                            hide-details
                                            :disabled="!isInRole(98)"
                                            dense
                                            persistent-hint
                                            :rules="rules"
                                            v-model="currencyGuid"
                                            @change="currencyChange"
                                            :items="currencies"
                                            item-text="currencySymbol"
                                            item-value="currencyGuid"
                                            :label="$t('voucher.currency')"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field
                                            outlined
                                            hide-details
                                            :disabled="!isInRole(98)"
                                            dense
                                            type="number"
                                            min="0"
                                            v-model="currencyValue"
                                            :label="$t('voucher.currencyValue')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-select
                                            outlined
                                            hide-details
                                            dense
                                            :disabled="!isInRole(98)"
                                            persistent-hint
                                            :rules="rules"
                                            v-model="editedItem.stockGuid"
                                            :items="stocks"
                                            item-text="stockName"
                                            item-value="stockGuid"
                                            :label="$t('stock.stock')"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-autocomplete
                                            :label="$t('customer.customer')"
                                            v-model="editedItem.customerGuid"
                                            outlined
                                            hide-details
                                            dense
                                            hide-selected
                                            :items="customers"
                                            item-text="fullName"
                                            item-value="customerGuid"
                                            :rules="rules"
                                            append-icon="mdi-plus"
                                            @click:append="
                                                customerDialog = true
                                            "
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-autocomplete
                                            :label="
                                                $t('itemTemplate.itemTemplates')
                                            "
                                            outlined
                                            v-model="planGuid"
                                            hide-details
                                            dense
                                            @change="changePlan"
                                            hide-selected
                                            :items="plans"
                                            item-text="title"
                                            item-value="planGuid"
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-textarea
                                            outlined
                                            hide-details
                                            dense
                                            rows="2"
                                            v-model="editedItem.notes"
                                            :label="$t('bill.notes')"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-2">
                                    <v-btn
                                        color="primary darken-1"
                                        icon
                                        class="mx-3"
                                        outlined
                                        :loading="loading"
                                        v-if="isInRole(98)"
                                        dark
                                        @click="addItem"
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                    <v-btn
                                        color="primary darken-1"
                                        outlined
                                        class="mx-3"
                                        dark
                                        @click="openQuantityDialog"
                                    >
                                        <v-icon>mdi-counter</v-icon>
                                        {{ $t("bill.uniteQuantity") }}
                                    </v-btn>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <div>
                                            <v-data-table
                                                :headers="headers"
                                                :items="editedItem.billBodies"
                                                :loading="loading"
                                                :loading-text="$t('loading')"
                                                hide-default-footer
                                                :height="260"
                                                :disable-pagination="true"
                                                class="myTable"
                                                @click:row="setIndex"
                                            >
                                                <template
                                                    v-slot:[`item.actions`]="{
                                                        item
                                                    }"
                                                >
                                                    <v-tooltip top>
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                                attrs
                                                            }"
                                                        >
                                                            <v-icon
                                                                @click="
                                                                    deleteBillBody(
                                                                        item
                                                                    )
                                                                "
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                color="red"
                                                                class="px-5"
                                                            >
                                                                mdi-delete-outline
                                                            </v-icon>
                                                        </template>
                                                        <span>
                                                            {{
                                                                $t("delete")
                                                            }}</span
                                                        >
                                                    </v-tooltip>
                                                </template>
                                                <template
                                                    v-slot:[`item.barcode`]="{
                                                        item
                                                    }"
                                                >
                                                    <v-text-field
                                                        flat
                                                        v-if="
                                                            rowIndex ==
                                                                editedItem.billBodies.indexOf(
                                                                    item
                                                                )
                                                        "
                                                        solo
                                                        :disabled="
                                                            !isInRole(98)
                                                        "
                                                        type="number"
                                                        dense
                                                        :autofocus="true"
                                                        v-model="item.barcode"
                                                        hide-details
                                                        v-on:change="
                                                            itemChanged(item, 1)
                                                        "
                                                        v-on:keyup.enter="
                                                            addItem
                                                        "
                                                    ></v-text-field>
                                                    <span v-else>{{
                                                        item.barcode
                                                    }}</span>
                                                </template>
                                                <template
                                                    v-slot:[`item.itemGuid`]="{
                                                        item
                                                    }"
                                                >
                                                    <v-autocomplete
                                                        flat
                                                        :disabled="
                                                            !isInRole(98)
                                                        "
                                                        v-if="
                                                            rowIndex ==
                                                                editedItem.billBodies.indexOf(
                                                                    item
                                                                )
                                                        "
                                                        solo
                                                        hide-details
                                                        dense
                                                        persistent-hint
                                                        :rules="rules"
                                                        v-model="item.itemGuid"
                                                        v-on:keyup.enter="
                                                            addItem
                                                        "
                                                        :items="items"
                                                        item-text="itemName"
                                                        item-value="itemGuid"
                                                        v-on:change="
                                                            itemChanged(item, 2)
                                                        "
                                                    ></v-autocomplete>
                                                    <span v-else>{{
                                                        getItemName(
                                                            item.itemGuid
                                                        )
                                                    }}</span>
                                                </template>
                                                <template
                                                    v-slot:[`item.price`]="{
                                                        item
                                                    }"
                                                >
                                                    <v-text-field
                                                        flat
                                                        :disabled="
                                                            !isInRole(98)
                                                        "
                                                        v-if="
                                                            rowIndex ==
                                                                editedItem.billBodies.indexOf(
                                                                    item
                                                                )
                                                        "
                                                        solo
                                                        type="number"
                                                        min="0"
                                                        dense
                                                        v-model="item.price"
                                                        v-on:keyup.enter="
                                                            addItem
                                                        "
                                                        hide-details
                                                    ></v-text-field>
                                                    <span v-else>{{
                                                        item.price
                                                    }}</span>
                                                </template>
                                                <template
                                                    v-slot:[`item.quantity`]="{
                                                        item
                                                    }"
                                                >
                                                    <v-text-field
                                                        flat
                                                        solo
                                                        type="number"
                                                        v-if="
                                                            rowIndex ==
                                                                editedItem.billBodies.indexOf(
                                                                    item
                                                                )
                                                        "
                                                        min="0"
                                                        dense
                                                        v-on:keyup.enter="
                                                            addItem
                                                        "
                                                        v-model="item.quantity"
                                                        hide-details
                                                    ></v-text-field>
                                                    <span v-else>{{
                                                        item.quantity
                                                    }}</span>
                                                </template>
                                                <template
                                                    v-slot:[`item.totalPrice`]="{
                                                        item
                                                    }"
                                                >
                                                    <v-text-field
                                                        flat
                                                        solo
                                                        v-if="
                                                            rowIndex ==
                                                                editedItem.billBodies.indexOf(
                                                                    item
                                                                )
                                                        "
                                                        type="number"
                                                        min="0"
                                                        dense
                                                        v-on:keyup.enter="
                                                            addItem
                                                        "
                                                        v-model="
                                                            item.totalPrice
                                                        "
                                                        hide-details
                                                    ></v-text-field>
                                                    <span v-else>{{
                                                        item.totalPrice
                                                    }}</span>
                                                </template>
                                                <template
                                                    v-slot:[`item.discount`]="{
                                                        item
                                                    }"
                                                >
                                                    <v-text-field
                                                        flat
                                                        solo
                                                        type="number"
                                                        min="0"
                                                        dense
                                                        v-on:keyup.enter="
                                                            addItem
                                                        "
                                                        v-model="item.discount"
                                                        hide-details
                                                    ></v-text-field>
                                                </template>
                                                <template
                                                    v-slot:[`item.extra`]="{
                                                        item
                                                    }"
                                                >
                                                    <v-text-field
                                                        flat
                                                        solo
                                                        type="number"
                                                        min="0"
                                                        dense
                                                        v-model="item.extra"
                                                        v-on:keyup.enter="
                                                            addItem
                                                        "
                                                        hide-details
                                                    ></v-text-field>
                                                </template>
                                                <template
                                                    v-slot:[`item.netPrice`]="{
                                                        item
                                                    }"
                                                >
                                                    <v-text-field
                                                        flat
                                                        v-if="
                                                            rowIndex ==
                                                                editedItem.billBodies.indexOf(
                                                                    item
                                                                )
                                                        "
                                                        solo
                                                        type="number"
                                                        min="0"
                                                        dense
                                                        readonly
                                                        v-model="item.netPrice"
                                                        hide-details
                                                    ></v-text-field>
                                                    <span v-else>{{
                                                        item.netPrice
                                                    }}</span>
                                                </template>
                                                <template
                                                    v-slot:[`item.notes`]="{
                                                        item
                                                    }"
                                                >
                                                    <v-text-field
                                                        flat
                                                        v-if="
                                                            rowIndex ==
                                                                editedItem.billBodies.indexOf(
                                                                    item
                                                                )
                                                        "
                                                        solo
                                                        dense
                                                        v-on:keyup.enter="
                                                            addItem
                                                        "
                                                        v-model="item.notes"
                                                        hide-details
                                                    ></v-text-field>
                                                    <span v-else>{{
                                                        item.notes
                                                    }}</span>
                                                </template>
                                            </v-data-table>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-divider></v-divider>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <!----------------Installments Tab--------------------->
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" md="2">
                                        <v-select
                                            outlined
                                            hide-details
                                            dense
                                            persistent-hint
                                            @change="createPayments"
                                            :rules="rules"
                                            v-model="
                                                editedItem.installment
                                                    .installmentType
                                            "
                                            :items="installmentTypes"
                                            item-text="text"
                                            item-value="value"
                                            :label="
                                                $t(
                                                    'installments.installmentType'
                                                )
                                            "
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field
                                            outlined
                                            @change="createPayments"
                                            type="number"
                                            min="0"
                                            dense
                                            :rules="rules"
                                            v-model="
                                                editedItem.installment
                                                    .paymentIncrement
                                            "
                                            :label="
                                                $t(
                                                    'installments.paymentIncrement'
                                                )
                                            "
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field
                                            outlined
                                            @change="createPayments"
                                            type="number"
                                            min="0"
                                            dense
                                            :rules="rules"
                                            v-model="
                                                editedItem.installment
                                                    .paymentCount
                                            "
                                            :label="
                                                $t('installments.paymentCount')
                                            "
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-select
                                            outlined
                                            hide-details
                                            dense
                                            persistent-hint
                                            :rules="rules"
                                            v-model="
                                                editedItem.installment
                                                    .installmentState
                                            "
                                            :items="installmentStates"
                                            @change="createPayments"
                                            item-text="text"
                                            item-value="value"
                                            :label="
                                                $t(
                                                    'installments.installmentState'
                                                )
                                            "
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <template>
                                            <div>
                                                <v-menu
                                                    ref="menu2"
                                                    v-model="menu2"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-text-field
                                                            hide-details
                                                            outlined
                                                            dense
                                                            :rules="rules"
                                                            v-model="
                                                                editedItem
                                                                    .installment
                                                                    .firstPaymentDate
                                                            "
                                                            :label="
                                                                $t(
                                                                    'installments.firstPaymentDate'
                                                                )
                                                            "
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        @change="saveDate2"
                                                        v-model="
                                                            editedItem
                                                                .installment
                                                                .firstPaymentDate
                                                        "
                                                        :active-picker.sync="
                                                            activePicker2
                                                        "
                                                        :max="
                                                            new Date(
                                                                Date.now() -
                                                                    new Date().getTimezoneOffset() *
                                                                        60000
                                                            )
                                                                .toISOString()
                                                                .substr(0, 10)
                                                        "
                                                        min="1950-01-01"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                        </template>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="
                                                editedItem.installment
                                                    .guarantyBy
                                            "
                                            :label="
                                                $t('installments.guarantyBy')
                                            "
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="
                                                editedItem.installment
                                                    .guarantor1Name
                                            "
                                            :label="
                                                $t('installments.guarantorName')
                                            "
                                            hide-details
                                        ></v-text-field> </v-col
                                    ><v-col cols="12" md="4">
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="
                                                editedItem.installment
                                                    .guarantor1Phone
                                            "
                                            :label="
                                                $t(
                                                    'installments.guarantorPhone'
                                                )
                                            "
                                            hide-details
                                        ></v-text-field> </v-col
                                    ><v-col cols="12" md="4">
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="
                                                editedItem.installment
                                                    .guarantor1Address
                                            "
                                            :label="
                                                $t(
                                                    'installments.guarantorAddress'
                                                )
                                            "
                                            hide-details
                                        ></v-text-field> </v-col
                                    ><v-col cols="12" md="4">
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="
                                                editedItem.installment
                                                    .guarantor2Name
                                            "
                                            :label="
                                                $t('installments.guarantorName')
                                            "
                                            hide-details
                                        ></v-text-field> </v-col
                                    ><v-col cols="12" md="4">
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="
                                                editedItem.installment
                                                    .guarantor2Phone
                                            "
                                            :label="
                                                $t(
                                                    'installments.guarantorPhone'
                                                )
                                            "
                                            hide-details
                                        ></v-text-field> </v-col
                                    ><v-col cols="12" md="4">
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="
                                                editedItem.installment
                                                    .guarantor2Address
                                            "
                                            :label="
                                                $t(
                                                    'installments.guarantorAddress'
                                                )
                                            "
                                            hide-details
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-data-table
                                            :headers="paymentsHeaders"
                                            :items="editedItem.payments"
                                            :loading="loading"
                                            :loading-text="$t('loading')"
                                            hide-default-footer
                                            :height="300"
                                            :disable-pagination="true"
                                            class="myTable"
                                        >
                                            <template
                                                v-slot:[`item.paymentAmount`]="{
                                                    item
                                                }"
                                            >
                                                <v-text-field
                                                    flat
                                                    solo
                                                    type="number"
                                                    min="0"
                                                    dense
                                                    :rules="rules"
                                                    :readonly="
                                                        item.isPaid ||
                                                            editedItem
                                                                .installment
                                                                .installmentState ==
                                                                2
                                                    "
                                                    v-model="item.paymentAmount"
                                                    hide-details
                                                ></v-text-field>
                                            </template>
                                            <template
                                                v-slot:[`item.actions`]="{
                                                    item
                                                }"
                                            >
                                                <v-tooltip
                                                    v-if="
                                                        editedItem.billGuid !=
                                                            null
                                                    "
                                                    top
                                                >
                                                    <template
                                                        v-if="
                                                            (isInRole(40) &&
                                                                item.isPaid ==
                                                                    false) ||
                                                                (isInRole(41) &&
                                                                    item.isPaid)
                                                        "
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-icon
                                                            class="
                                                                        mx-4
                                                                    "
                                                            :color="
                                                                item.isPaid
                                                                    ? 'red'
                                                                    : 'blue'
                                                            "
                                                            @click="
                                                                payPayment(item)
                                                            "
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            {{
                                                                item.isPaid
                                                                    ? "mdi-cancel"
                                                                    : "mdi-hand-coin-outline"
                                                            }}
                                                        </v-icon>
                                                    </template>
                                                    <span>
                                                        {{
                                                            item.isPaid
                                                                ? $t("cancel") +
                                                                  " " +
                                                                  $t(
                                                                      "payments.pay"
                                                                  )
                                                                : $t(
                                                                      "payments.pay"
                                                                  )
                                                        }}</span
                                                    >
                                                </v-tooltip>
                                                <v-tooltip
                                                    top
                                                    v-if="
                                                        isInRole(42) &&
                                                            editedItem.billGuid !=
                                                                null &&
                                                            item.isPaid == true
                                                    "
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-icon
                                                            class="
                                                                        mx-4
                                                                    "
                                                            @click="
                                                                printPayment(
                                                                    item.paymentGuid
                                                                )
                                                            "
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                            mdi-printer-outline
                                                        </v-icon>
                                                    </template>
                                                    <span>
                                                        {{ $t("print") }}</span
                                                    >
                                                </v-tooltip>
                                            </template>
                                            <template
                                                v-slot:[`item.isPaid`]="{
                                                    item
                                                }"
                                            >
                                                {{
                                                    item.isPaid
                                                        ? $t("payStatus.paid")
                                                        : $t("payStatus.unpaid")
                                                }}
                                            </template>
                                            <template
                                                v-slot:[`item.receivedDate`]="{
                                                    item
                                                }"
                                            >
                                                {{
                                                    item.isPaid
                                                        ? $moment(
                                                              item.receivedDate
                                                          ).format("yyyy-MM-DD")
                                                        : ""
                                                }}
                                            </template>
                                            <template
                                                v-slot:[`item.paymentDate`]="{
                                                    item
                                                }"
                                            >
                                                {{
                                                    item.paymentDate
                                                        | moment("yyyy-MM-DD")
                                                }}
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                                <v-divider></v-divider>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
                <v-card-text>
                    <v-row class="mt-2">
                        <v-col cols="12" md="2">
                            <v-text-field
                                class="font-weight-black centered-input"
                                outlined
                                dense
                                hide-details
                                type="number"
                                readonly
                                :change="totalNetCalculation()"
                                v-model="editedItem.totalAmount"
                                :label="$t('bill.totalAmount')"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-text-field
                                class="font-weight-black centered-input"
                                outlined
                                dense
                                hide-details
                                type="number"
                                :disabled="!isInRole(98)"
                                min="0"
                                :rules="rules"
                                :change="totalNetCalculation()"
                                v-model="editedItem.totalDiscount"
                                :label="$t('bill.totalDiscount')"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-text-field
                                class="font-weight-black centered-input"
                                outlined
                                dense
                                hide-details
                                :disabled="!isInRole(98)"
                                type="number"
                                min="0"
                                :rules="rules"
                                v-model="editedItem.totalExtra"
                                :change="totalNetCalculation()"
                                :label="$t('bill.totalExtra')"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-text-field
                                class="font-weight-black centered-input"
                                outlined
                                dense
                                hide-details
                                readonly
                                type="number"
                                v-model="editedItem.totalNet"
                                :label="$t('bill.totalNet')"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-text-field
                                class="font-weight-black centered-input"
                                outlined
                                dense
                                hide-details
                                readonly
                                type="number"
                                v-model="receivedAmount"
                                :label="$t('bill.receivedAmount')"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-text-field
                                class="font-weight-black centered-input"
                                outlined
                                dense
                                hide-details
                                readonly
                                type="number"
                                v-model="remainingAmount"
                                :label="$t('bill.remainingAmount')"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-divider class="mt-3"></v-divider>
                </v-card-text>
                <v-card-actions>
                    <v-row>
                        <v-col>
                            <v-row no-gutters>
                                <v-col cols="auto" class="ma-1">
                                    <v-btn
                                        text
                                        :loading="loading"
                                        color="primary darken-1"
                                        @click="newEntity"
                                        :min-width="100"
                                    >
                                        {{ $t("new") }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto" class="ma-1">
                                    <v-btn
                                        :loading="loading"
                                        v-if="isInRole(37)"
                                        color="primary darken-1 white--text"
                                        @click="save"
                                        :disabled="!valid"
                                        :min-width="100"
                                    >
                                        <v-icon
                                            >mdi-content-save-outline</v-icon
                                        >
                                        {{ $t("save") }}
                                    </v-btn></v-col
                                >
                                <v-col cols="auto" class="ma-1">
                                    <v-btn
                                        :loading="loading"
                                        v-if="
                                            isInRole(39) &&
                                                editedItem.billGuid != null
                                        "
                                        @click="printBill"
                                        text
                                        color="primary darken-1"
                                        :disabled="!valid"
                                        :min-width="100"
                                    >
                                        <v-icon>mdi-printer-outline</v-icon>
                                        {{ $t("print") }}
                                    </v-btn></v-col
                                >
                                <v-col cols="auto" class="ma-1">
                                    <v-switch
                                        v-if="isInRole(37)"
                                        class="mt-0"
                                        hide-details
                                        v-model="editedItem.isLock"
                                        @change="lockBill"
                                        :disabled="editedItem.isAccept"
                                        :label="$t('bill.isLock')"
                                    ></v-switch
                                ></v-col>
                                <v-col cols="auto" class="ma-1">
                                    <v-switch
                                        v-if="isInRole(37)"
                                        class="mt-0 "
                                        hide-details
                                        @change="acceptBill"
                                        v-model="editedItem.isAccept"
                                        :label="$t('bill.isAccept')"
                                        :change="isAcceptChange()"
                                    ></v-switch
                                ></v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn
                                :loading="loading"
                                text
                                :disabled="
                                    editedItem.billGuid == null ||
                                        editedItem.billGuid ==
                                            '00000000-0000-0000-0000-000000000000'
                                "
                                color="red"
                                class="mx-2 mt-1"
                                v-if="isInRole(38)"
                                @click="deleteItem()"
                                :min-width="100"
                            >
                                <v-icon>mdi-delete-outline</v-icon>
                                {{ $t("delete") }}
                            </v-btn></v-col
                        >
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-form>
        <!--Delete-->
        <confirm-dialog
            :openDialog="dialogDelete"
            :onClicked="deleteItemConfirm"
            :onClose="closeDelete"
            toolBarColor="red darken-2"
        ></confirm-dialog>
        <!--Un/Pay Confirmation-->
        <confirm-dialog
            :openDialog="dialogPayConfirm"
            :onClicked="savePayment"
            :onClose="closeConfirm"
            toolBarColor="primary"
            dialogTitle=" "
        ></confirm-dialog>
        <!--Delete Bill-Body confirm-->
        <confirm-dialog
            :openDialog="deleteBillBodyConfirm"
            :onClicked="deleteBodyConfirm"
            :onClose="closeDeleteBody"
            :toolBarColor="'red'"
        ></confirm-dialog>
        <Customer-Dialog
            :editedItem="customer"
            :editedIndex="-1"
            :dialog="customerDialog"
            :close="closeCustomerDialog"
            :refreshCustomers="refreshCustomers"
            @select-customer="selectCustomer"
        />
    </v-container>
</template>
<script>
import axios from "axios";
import moment from "moment";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";

import CustomerDialog from "../Customer/CustomerDialog.vue";

export default {
    components: { ConfirmDialog, CustomerDialog, PageHeader },
    props: ["bType"],
    data() {
        return {
            unitedQuantity: 0,
            quantityDialog: false,
            planGuid: null,
            plans: [],
            rowIndex: -1,
            customer: {
                customerGuid: null
            },
            customerDialog: false,
            currencyGuid: null,
            currencyValue: null,
            newButtonPressed: false,
            deletedBillBody: null,
            deleteBillBodyConfirm: false,
            remainingAmount: null,
            receivedAmount: null,
            deletePaymentObject: null,
            installmentTypes: [
                {
                    text: this.$t("installmentTypes.daily"),
                    value: 1
                },
                {
                    text: this.$t("installmentTypes.weekly"),
                    value: 2
                },
                {
                    text: this.$t("installmentTypes.monthly"),
                    value: 3
                },
                {
                    text: this.$t("installmentTypes.yearly"),
                    value: 4
                }
            ],
            installmentStates: [
                {
                    text: this.$t("installmentStates.free"),
                    value: 1
                },
                {
                    text: this.$t("installmentStates.equal"),
                    value: 2
                }
            ],
            dialogPayConfirm: false,
            tab: null,
            tabsNames: [this.$t("bill.billInfo"), this.$t("installments.main")],
            focusedRowKey: 0,
            lastPayType: null,
            autoNavigateToFocusedRow: true,
            startEditAction: "click",
            id: this.$route.params.id,
            activePicker1: null,
            activePicker2: null,
            date: null,
            menu: false,
            menu2: false,
            paymentsHeaders: [
                {
                    text: this.$t("payments.paymentNumber"),
                    value: "paymentNumber"
                },
                {
                    text: this.$t("payments.paymentDate"),
                    value: "paymentDate"
                },
                {
                    text: this.$t("payments.receivedDate"),
                    value: "receivedDate"
                },
                {
                    text: this.$t("payments.paymentAmount"),
                    value: "paymentAmount"
                },
                {
                    text: this.$t("payments.isPaid"),
                    value: "isPaid"
                },
                { text: "", value: "actions" }
            ],
            headers: [
                {
                    text: this.$t("billBody.barcode"),
                    value: "barcode"
                },
                {
                    text: this.$t("billBody.item"),
                    value: "itemGuid"
                },
                {
                    text: this.$t("billBody.price"),
                    value: "price"
                },
                {
                    text: this.$t("billBody.quantity"),
                    value: "quantity"
                },
                /* {
                    text: this.$t("billBody.totalPrice"),
                    value: "totalPrice"
                },
                {
                    text: this.$t("billBody.discount"),
                    value: "discount"
                },
                {
                    text: this.$t("billBody.extra"),
                    value: "extra"
                }, */
                {
                    text: this.$t("billBody.netPrice"),
                    value: "netPrice"
                },
                {
                    text: this.$t("billBody.notes"),
                    value: "notes"
                },

                { text: "", value: "actions" }
            ],
            priceTypes: [
                { key: this.$t("priceTypes.0"), value: 0 },
                { key: this.$t("priceTypes.1"), value: 1 },
                { key: this.$t("priceTypes.2"), value: 2 },
                { key: this.$t("priceTypes.3"), value: 3 },
                { key: this.$t("priceTypes.4"), value: 4 },
                { key: this.$t("priceTypes.5"), value: 5 }
            ],
            payTypes: [
                { key: this.$t("payTypes.cash"), value: 1 },
                { key: this.$t("payTypes.delayed"), value: 2 }
            ],
            billType: this.bType,
            billTypes: [
                { key: 0, value: "rubbish -> used for indexing" },
                { key: 1, value: "Purchase" },
                { key: 2, value: "Sale" },
                { key: 3, value: "PurchaseReturn" },
                { key: 4, value: "SaleReturn" }
            ],
            valid: true,
            loading: true,
            dialogDelete: false,
            editedIndex: -1,
            search: "",
            billSetting: null,
            priceType: null,
            accounts: [],
            accountsContra: [],
            customers: [],
            stocks: [],
            items: [],
            currencies: [],
            billBodies: [],
            payments: [],
            editedItem: {
                billGuid: null,
                billSettingGuid: null,
                customerGuid: null,
                accountGuid: null,
                accountContraGuid: null,
                stockGuid: null,
                billType: null,
                billDate: moment(new Date()).format("yyyy-MM-DD"),
                billNumber: null,
                currencyGuid: null,
                currencyValue: null,
                totalAmount: null,
                totalDiscount: 0,
                totalExtra: 0,
                totalNet: null,
                notes: null,
                payType: null,
                isLock: false,
                isAccept: false,
                isPaid: false,
                isMaintaince: false,
                createdDate: null,
                createdUserGuid: null,
                modifiedDate: null,
                modifiedUserGuid: null,
                branchGuid: null,
                billBodies: [],
                installment: {
                    installmentGuid: null,
                    billGuid: null,
                    installmentType: null,
                    installmentState: null,
                    paymentCount: null,
                    paymentIncrement: null,
                    firstPaymentDate: null,
                    guarantor1Name: null,
                    guarantor1Phone: null,
                    guarantor1Address: null,
                    guarantor1FileUrl: null,
                    guarantor2Name: null,
                    guarantor2Phone: null,
                    guarantor2Address: null,
                    guarantor2FileUrl: null,
                    guarantyBy: null
                },
                payments: []
            },
            defaultItem: {
                billGuid: null,
                billSettingGuid: null,
                customerGuid: null,
                accountGuid: null,
                accountContraGuid: null,
                stockGuid: null,
                billType: null,
                billDate: moment(new Date()).format("yyyy-MM-DD"),
                billNumber: null,
                currencyGuid: null,
                currencyValue: null,
                totalAmount: null,
                totalDiscount: 0,
                totalExtra: 0,
                totalNet: null,
                notes: null,
                payType: null,
                isLock: false,
                isAccept: false,
                isPaid: false,
                isMaintaince: false,
                createdDate: null,
                createdUserGuid: null,
                modifiedDate: null,
                modifiedUserGuid: null,
                branchGuid: null,
                billBodies: [],
                installment: {
                    installmentGuid: null,
                    billGuid: null,
                    installmentType: null,
                    installmentState: null,
                    paymentCount: null,
                    paymentIncrement: null,
                    firstPaymentDate: null,
                    guarantor1Name: null,
                    guarantor1Phone: null,
                    guarantor1Address: null,
                    guarantor1FileUrl: null,
                    guarantor2Name: null,
                    guarantor2Phone: null,
                    guarantor2Address: null,
                    guarantor2FileUrl: null,
                    guarantyBy: null
                },
                payments: []
            },
            rules: [value => !!value || value == 0 || this.$t("required")]
        };
    },
    created() {
        if (this.isInRole(36)) {
            this.loading = true;
            this.refreshData();
            this.getPlans();
        } else this.redirectUnauthorizedUsers();
    },
    watch: {
        dialogDelete(val) {
            val;
        },
        dialogPayConfirm(val) {
            val;
        },
        menu(val) {
            val && setTimeout(() => (this.activePicker1 = "DATE"));
        },
        menu2(val) {
            val && setTimeout(() => (this.activePicker2 = "DATE"));
        },
        $route(val) {
            if (val != this.$route.name) {
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -2;
                });
                this.$refs.form.reset();
                this.$refs.form.resetValidation();
                this.billBodies = [];
                this.id = null;
                this.refreshData();
            }
        },
        "editedItem.billBodies": {
            handler: function() {
                var mainCurrency = this.currencies.filter(function(obj) {
                    return obj.isMain == true;
                })[0];

                var billCurrency = {
                    currencyGuid: this.currencyGuid,
                    currency: {
                        currencyValue: this.currencyValue
                    }
                };

                for (
                    let index = 0;
                    index < this.editedItem.billBodies.length;
                    index++
                ) {
                    var element = this.editedItem.billBodies[index];

                    element.netPrice =
                        Number(element.price) * Number(element.quantity) +
                        Number(element.extra) -
                        Number(element.discount);
                    element.finalPrice = element.netPrice;

                    var item = null;

                    if (element.billBodyGuid != undefined) {
                        item = {
                            price:
                                element.finalPrice -
                                element.quantity * element.costPrice,
                            currencyGuid: billCurrency.currencyGuid,
                            currency: billCurrency.currency
                        };

                        element.profit = this.currencyConverter(
                            mainCurrency,
                            item
                        );
                    } else {
                        item = {
                            price:
                                element.finalPrice -
                                element.quantity * element.itemCostPrice,
                            currencyGuid: billCurrency.currencyGuid,
                            currency: billCurrency.currency
                        };

                        element.profit = this.currencyConverter(
                            mainCurrency,
                            item
                        );

                        element.costPrice =
                            element.itemCostPrice * element.quantity;
                    }
                    element.lastBuyPrice = Number(element.price);
                    element.totalPrice = element.price * element.quantity;
                }
                this.editedItem.totalAmount = this.editedItem.billBodies.reduce(
                    (a, b) => +a + +b.netPrice,
                    0
                );
            },
            deep: true
        }
    },
    methods: {
        uniteQuantity() {
            if (this.editedItem.billBodies.length > 0) {
                var quantity = this.unitedQuantity;

                this.editedItem.billBodies.forEach(element => {
                    element.quantity = quantity;
                });
            }
            this.quantityDialog = false;
        },
        openQuantityDialog() {
            this.quantityDialog = true;
        },
        getPlans() {
            axios.get("Plans/Get").then(response => {
                this.plans = response.data.data;
            });
        },
        changePlan() {
            if (this.planGuid != null || this.planGuid != undefined) {
                var selectedPlan = this.planGuid;

                var items = this.plans.find(function(obj) {
                    return obj.planGuid == selectedPlan;
                }).planItems;

                this.editedItem.billBodies = items;

                for (let i = 0; i < items.length; i++) {
                    this.itemChanged(items[i], 2);
                }
            }
        },
        deleteBillBody(item) {
            this.deletedBillBody = item;

            if (
                (this.deletedBillBody != undefined ||
                    this.deletedBillBody != null) &&
                (this.deletedBillBody.billBodyGuid != undefined ||
                    this.deletedBillBody.billBodyGuid != null)
            )
                this.deleteBillBodyConfirm = true;
            else this.deleteBodyConfirm();
        },
        deleteBodyConfirm() {
            var index = this.editedItem.billBodies.indexOf(
                this.deletedBillBody
            );
            this.editedItem.billBodies.splice(index, 1);
            this.closeDeleteBody();
        },
        closeDeleteBody() {
            this.deleteBillBodyConfirm = false;
        },
        remainingAndReceived() {
            var received = 0;

            for (
                let index = 0;
                index < this.editedItem.payments.length;
                index++
            ) {
                if (this.editedItem.payments[index].isPaid)
                    received += Number(
                        this.editedItem.payments[index].paymentAmount
                    );
            }

            this.receivedAmount = received;

            this.remainingAmount =
                this.editedItem.totalNet - this.receivedAmount;
        },
        payPayment(payment) {
            this.dialogPayConfirm = true;
            this.deletePaymentObject = payment;
        },
        savePayment() {
            var previousIndex =
                this.editedItem.payments.indexOf(this.deletePaymentObject) - 1;
            var previousPayment =
                previousIndex != -1
                    ? this.editedItem.payments[previousIndex]
                    : null;

            if (this.deletePaymentObject.isPaid == false) {
                if (
                    this.deletePaymentObject.paymentAmount < 0 ||
                    this.deletePaymentObject.paymentAmount == 0
                ) {
                    this.$toast.warning(
                        this.$t("error.paymentAmountCannotBeNegative")
                    );
                    this.dialogPayConfirm = false;
                    return;
                }

                if (
                    this.deletePaymentObject.paymentAmount >
                    this.remainingAmount
                ) {
                    this.$toast.warning(
                        this.$t(
                            "error.paymentAmountCannotBeGreaterThanRemainingAmount"
                        )
                    );
                    this.dialogPayConfirm = false;
                    return;
                }

                if (
                    (previousPayment != null && previousPayment.isPaid) ||
                    this.deletePaymentObject.paymentNumber == 1
                ) {
                    axios
                        .post("Payments/Pay", this.deletePaymentObject)
                        .then(response => {
                            if (response.data.status == "Successful") {
                                this.deletePaymentObject.isPaid = true;
                                this.deletePaymentObject.receivedDate =
                                    response.data.data.receivedDate;
                                this.deletePaymentObject.voucherGuid =
                                    response.data.data.voucherGuid;
                                this.editedItem.payments[
                                    this.editedItem.payments.indexOf(
                                        this.deletePaymentObject
                                    )
                                ] = this.deletePaymentObject;

                                this.remainingAndReceived();

                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            console.log(e);
                        });
                } else {
                    this.$toast.warning(
                        this.$t("error.MustPayThePreviousPayments")
                    );
                }
            } else {
                axios
                    .post("Payments/Unpay", this.deletePaymentObject)
                    .then(response => {
                        if (response.data.status == "Successful") {
                            this.deletePaymentObject.isPaid = false;
                            this.deletePaymentObject.voucherGuid =
                                response.data.data.voucherGuid;
                            this.editedItem.payments[
                                this.editedItem.payments.indexOf(
                                    this.deletePaymentObject
                                )
                            ] = this.deletePaymentObject;
                            this.remainingAndReceived();
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    });
            }
            this.dialogPayConfirm = false;
        },
        createPayments() {
            if (
                this.editedItem.installment.installmentType != null &&
                this.editedItem.installment.paymentCount != null &&
                this.editedItem.installment.paymentCount != 0 &&
                this.editedItem.installment.paymentIncrement != null &&
                this.editedItem.installment.paymentIncrement != 0 &&
                this.editedItem.installment.firstPaymentDate != null &&
                this.editedItem.totalNet != 0
            ) {
                var paidPayments = this.editedItem.payments.filter(function(
                    obj
                ) {
                    return obj.isPaid == true;
                });

                this.editedItem.payments = [];

                //Payments dates start from
                //it should be ordered acc.
                var lastDate =
                    paidPayments.length == 0
                        ? new Date(this.editedItem.installment.firstPaymentDate)
                        : paidPayments[paidPayments.length - 1].paymentDate;

                var frequentAmount = 0;

                if (paidPayments.length == 0)
                    frequentAmount =
                        this.editedItem.totalNet /
                        this.editedItem.installment.paymentCount;
                else
                    frequentAmount =
                        this.remainingAmount /
                        (this.editedItem.installment.paymentCount -
                            paidPayments.length);

                this.editedItem.payments = [...paidPayments];

                var timePeriod = "";
                switch (this.editedItem.installment.installmentType) {
                    case 1:
                        timePeriod = "days";
                        break;
                    case 2:
                        timePeriod = "weeks";
                        break;
                    case 3:
                        timePeriod = "months";
                        break;
                    case 4:
                        timePeriod = "years";
                        break;
                    default:
                        break;
                }

                if (paidPayments.length != 0)
                    lastDate = moment(lastDate).add(
                        Number(this.editedItem.installment.paymentIncrement),
                        timePeriod
                    );

                for (
                    let i = 0;
                    i <
                    this.editedItem.installment.paymentCount -
                        paidPayments.length;
                    i++
                ) {
                    var payment = {
                        paymentNumber: i + 1,
                        paymentDate: moment(lastDate).format("yyyy-MM-DD"),
                        receivedDate: moment(lastDate).format("yyyy-MM-DD"),
                        paymentAmount: frequentAmount,
                        isPaid: false
                    };

                    lastDate = moment(lastDate).add(
                        Number(this.editedItem.installment.paymentIncrement),
                        timePeriod
                    );
                    this.editedItem.payments.push(payment);
                }

                //re-arrange payments numbers
                for (let i = 0; i < this.editedItem.payments.length; i++) {
                    this.editedItem.payments[i].paymentNumber = i + 1;
                }
            }
        },
        currencyChange(val) {
            if (val != null || val != undefined) {
                if (this.editedItem.billGuid == null) {
                    var x = this.currencies.filter(function(obj) {
                        return obj.currencyGuid == val;
                    });

                    this.currencyValue = x[0].currencyValue;
                    this.isCurrencyMain = x[0].isMain;
                } else if (
                    this.editedItem.billGuid != null &&
                    this.currencyGuid != val
                ) {
                    var billCurrency = this.currencies.filter(function(obj) {
                        return obj.currencyGuid == val;
                    })[0];

                    this.currencyValue = billCurrency.currencyValue;
                    this.isCurrencyMain = billCurrency.isMain;
                } else {
                    var temp = this.currencyGuid;
                    var c = this.currencies.filter(function(obj) {
                        return obj.currencyGuid == temp;
                    });

                    this.currencyValue = this.editedItem.currencyValue;
                    this.isCurrencyMain = c[0].isMain;
                }
            }
        },
        isAcceptChange() {
            if (this.editedItem.isAccept) this.editedItem.isLock = true;
        },
        totalNetCalculation() {
            this.editedItem.totalNet =
                Number(this.editedItem.totalAmount) +
                Number(this.editedItem.totalExtra) -
                Number(this.editedItem.totalDiscount);
        },
        newEntity() {
            this.$router
                .push({
                    name: "bill.installmentsSaleBill",
                    props: { vType: this.bType }
                })
                .catch(error => {
                    error;
                });

            /* this.newButtonPressed = true;
            this.$refs.form.reset();

            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });

            this.$refs.form.resetValidation();
            this.billBodies = [];
            this.id = null;
            this.refreshData(); */
        },
        currencyConverter(bill, item) {
            if (bill.currencyGuid == item.currencyGuid)
                return Number(item.price);
            else
                return (
                    Math.round(
                        ((Number(item.price) *
                            Number(item.currency.currencyValue)) /
                            Number(bill.currencyValue) +
                            Number.EPSILON) *
                            100
                    ) / 100
                );
        },
        itemChanged: function(item, selection) {
            var index = this.editedItem.billBodies.indexOf(item);
            var row = this.editedItem.billBodies[index];

            if (this.currencyValue == null || this.currencyValue == 0) {
                this.$toast.warning(this.$t("error.InsertCurrencyValue"));
                this.editedItem.billBodies.splice(index, 1);
                return;
            }

            var selectedItems = null;

            if (selection == 1) {
                selectedItems = this.items.filter(function(obj) {
                    return (
                        obj.barcode1 == row.barcode ||
                        obj.barcode2 == row.barcode ||
                        obj.barcode2 == row.barcode
                    );
                });
            } else {
                selectedItems = this.items.filter(function(obj) {
                    return obj.itemGuid == row.itemGuid;
                });
            }

            if (
                selectedItems == undefined ||
                selectedItems == null ||
                selectedItems.length == 0
            ) {
                this.$toast.warning(this.$t("error.ItemNotExists"));
                return;
            }

            var selectedItem = selectedItems[0];

            var bill = {
                currencyGuid: this.currencyGuid,
                currencyValue: this.currencyValue
            };

            var mainCurrency = this.currencies.filter(function(obj) {
                return obj.isMain == true;
            })[0];

            row.barcode = selectedItem.barcode1;
            row.itemGuid = selectedItem.itemGuid;
            row.quantity = 1;
            row.discount = 0;
            row.extra = 0;

            //convert the selectedItem currency to bill currency
            selectedItem.price = selectedItem.costPrice;

            if (this.currencyGuid == mainCurrency.currencyGuid) {
                row.itemCostPrice = selectedItem.price;
            } else {
                row.itemCostPrice = selectedItem.costPrice = this.currencyConverter(
                    bill,
                    {
                        price: selectedItem.price,
                        currencyGuid: mainCurrency.currencyGuid,
                        currency: mainCurrency
                    }
                );
            }

            switch (this.priceType) {
                case 1:
                    selectedItem.price = selectedItem.price1;
                    row.price = this.currencyConverter(bill, selectedItem);
                    break;
                case 2:
                    selectedItem.price = selectedItem.price2;
                    row.price = this.currencyConverter(bill, selectedItem);
                    break;
                case 3:
                    selectedItem.price = selectedItem.price3;
                    row.price = this.currencyConverter(bill, selectedItem);
                    break;
                case 4:
                    selectedItem.price = selectedItem.lastBuyPrice;

                    if (this.currencyGuid == mainCurrency.currencyGuid) {
                        row.price = selectedItem.price;
                    } else {
                        row.price = this.currencyConverter(bill, {
                            price: selectedItem.price,
                            currencyGuid: mainCurrency.currencyGuid,
                            currency: mainCurrency
                        });
                    }
                    break;
                case 5:
                    selectedItem.price = selectedItem.costPrice;
                    if (this.currencyGuid == mainCurrency.currencyGuid) {
                        row.price = selectedItem.price;
                    } else {
                        row.price = this.currencyConverter(bill, {
                            price: selectedItem.price,
                            currencyGuid: mainCurrency.currencyGuid,
                            currency: mainCurrency
                        });
                    }
                    break;
                default:
                    selectedItem.price = 0;
                    row.price = 0;
                    break;
            }

            //save the "last buy price" for selectedItem in main currency
            bill = {
                currencyGuid: mainCurrency.currencyGuid,
                currencyValue: mainCurrency.currencyValue
            };

            if (this.currencyGuid == mainCurrency.currencyGuid) {
                row.lastBuyPrice = selectedItem.price;
            } else {
                row.lastBuyPrice = this.currencyConverter(bill, {
                    price: selectedItem.price,
                    currencyGuid: mainCurrency.currencyGuid,
                    currency: mainCurrency
                });
            }

            this.editedItem.billBodies.splice(index, 1);
            this.editedItem.billBodies.splice(index, 0, row);
        },
        getSettingsByType() {
            axios
                .get("Bills/GetBillSettingsByType?type=" + this.bType)
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.billSetting = response.data.data[0];

                        this.editedItem.billType = this.bType;
                        this.editedItem.billSettingGuid =
                            response.data.data[0].billSettingGuid;

                        this.editedItem.accountGuid = this.billSetting.accountGuid;
                        this.editedItem.accountContraGuid = this.billSetting.accountContraGuid;
                        this.editedItem.stockGuid = this.billSetting.stockGuid;
                        this.editedItem.customerGuid = this.billSetting.customerGuid;
                        this.editedItem.payType = this.billSetting.payType;
                        this.priceType = this.billSetting.priceType;

                        var temp = this.billSetting.currencyGuid;
                        var c = this.currencies.filter(function(obj) {
                            return obj.currencyGuid == temp;
                        });

                        this.currencyGuid = c[0].currencyGuid;
                        this.currencyValue = c[0].currencyValue;
                        this.isCurrencyMain = c[0].isMain;

                        var dto = {
                            billType: this.bType,
                            billSettingGuid:
                                response.data.data[0].billSettingGuid
                        };

                        axios
                            .post("Bills/GenerateBillNumber", dto)
                            .then(response => {
                                if (response.data.status == "Successful") {
                                    this.editedItem.billNumber =
                                        response.data.data;
                                } else {
                                    this.$toast.error(
                                        this.$t(
                                            "error." + response.data.message
                                        )
                                    );
                                }
                            })
                            .catch(e => {
                                this.$toast.error(
                                    this.$t("AnErrorOccurredDuringTheProcess")
                                );
                                console.log(e);
                            });
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                });
        },
        saveDate1(date) {
            this.$refs.menu1.save(date);
        },
        saveDate2(date) {
            this.$refs.menu2.save(date);
            this.createPayments();
        },
        deleteItem() {
            this.dialogDelete = true;
        },
        addItem() {
            this.editedItem.billBodies.push({
                quantity: 0,
                discount: 0,
                extra: 0,
                price: 0
            });
            this.rowIndex = this.editedItem.billBodies.length - 1;
        },
        removeItem(item) {
            var index = this.editedItem.billBodies.indexOf(item);
            this.editedItem.billBodies.splice(index, 1);
        },
        deleteItemConfirm() {
            axios
                .delete("Bills/DeleteBill?id=" + this.editedItem.billGuid)
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.newEntity();
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    this.refreshData();
                    console.log(e);
                });

            this.closeDelete();
        },
        closeDelete() {
            this.dialogDelete = false;
        },
        closeConfirm() {
            this.dialogPayConfirm = false;
        },
        getCurrencies() {
            axios
                .get("Currencies/Get")
                .then(response => {
                    this.currencies = response.data.data;
                    this.refreshData();
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        refreshData() {
            try {
                axios
                    .get("Bills/NewBill?billType=" + this.bType)
                    .then(response => {
                        if (response.data.status == "Successful") {
                            //Accounts
                            this.accounts = response.data.data.accounts.filter(
                                function(obj) {
                                    return (
                                        obj.parentGuid !=
                                        "00000000-0000-0000-0000-000000000000"
                                    );
                                }
                            );

                            //Customers
                            this.customers = response.data.data.customers;

                            //Currencies
                            this.currencies = response.data.data.currencies;

                            //Stocks
                            this.stocks = response.data.data.stocks;

                            //Bill Setting
                            this.billSetting = response.data.data.billSetting;

                            this.editedItem.billType = this.bType;
                            this.editedItem.billSettingGuid = this.billSetting.billSettingGuid;

                            this.editedItem.accountGuid = this.billSetting.accountGuid;
                            this.editedItem.accountContraGuid = this.billSetting.accountContraGuid;
                            this.editedItem.stockGuid = this.billSetting.stockGuid;
                            this.editedItem.customerGuid = this.billSetting.customerGuid;
                            this.editedItem.payType = this.billSetting.payType;
                            this.priceType = this.billSetting.priceType;
                            this.editedItem.installment.installmentType = this.billSetting.installmentType;
                            this.editedItem.installment.paymentIncrement = this.billSetting.paymentIncrement;
                            this.editedItem.installment.paymentCount = this.billSetting.paymentNumber;
                            this.editedItem.installment.firstPaymentDate = moment(
                                new Date()
                            ).format("YYYY-MM-DD");
                            this.editedItem.installment.installmentState = 2;

                            var temp = this.billSetting.currencyGuid;
                            var c = this.currencies.filter(function(obj) {
                                return obj.currencyGuid == temp;
                            });

                            this.currencyGuid = c[0].currencyGuid;
                            this.currencyValue = c[0].currencyValue;
                            this.isCurrencyMain = c[0].isMain;

                            //Bill Number
                            this.editedItem.billNumber =
                                response.data.data.billNumber;

                            //Items
                            this.items = response.data.data.items.filter(
                                function(obj) {
                                    return obj.isGroup != true;
                                }
                            );

                            //Get Bill
                            if (this.id) {
                                axios
                                    .get("Bills/GetBills/" + this.id)
                                    .then(response => {
                                        this.editedItem = response.data.data;
                                        this.editedItem.payType = Number(
                                            this.editedItem.payType
                                        );
                                        this.editedIndex = 0;
                                        this.lastPayType = this.editedItem.payType;

                                        this.currencyValue = this.editedItem.currencyValue;
                                        this.currencyGuid = this.editedItem.currencyGuid;

                                        this.editedItem.billDate = moment(
                                            this.editedItem.billDate
                                        ).format("yyyy-MM-DD");

                                        this.editedItem.installment.firstPaymentDate = moment(
                                            this.editedItem.installment
                                                .firstPaymentDate
                                        ).format("YYYY-MM-DD");

                                        this.remainingAndReceived();
                                    })
                                    .catch(e => {
                                        this.$toast.error(
                                            this.$t(
                                                "AnErrorOccurredDuringTheProcess"
                                            )
                                        );
                                        console.log(e);
                                    })
                                    .finally(() => {
                                        this.loading = false;
                                    });
                            }
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        save() {
            try {
                if (this.$refs.form.validate()) {
                    if (this.editedIndex == -1) {
                        this.editedItem.billGuid =
                            "00000000-0000-0000-0000-000000000000";

                        this.editedItem.installment.installmentGuid =
                            "00000000-0000-0000-0000-000000000000";

                        this.editedItem.billSettingGuid = this.billSetting.billSettingGuid;
                        this.editedItem.billType = this.bType;
                        this.editedItem.payType = 2;
                    }

                    if (
                        this.editedItem.accountGuid ==
                        this.editedItem.accountContraGuid
                    ) {
                        this.$toast.error(
                            this.$t(
                                "error.AccountAndAccountContraCannotBeTheSame"
                            )
                        );
                        return;
                    }

                    if (
                        this.editedItem.billBodies == null ||
                        this.editedItem.billBodies.length == 0
                    ) {
                        this.$toast.error(this.$t("error.NoBillBodiesFound"));
                        return;
                    }

                    if (
                        this.editedItem.payments == null ||
                        this.editedItem.payments.length == 0
                    ) {
                        this.$toast.warning(this.$t("error.NoPaymentsFound"));
                        return;
                    }

                    if (
                        this.editedItem.totalDiscount == undefined ||
                        this.editedItem.totalDiscount == null ||
                        this.editedItem.totalDiscount == ""
                    )
                        this.editedItem.totalDiscount = 0;

                    if (
                        this.editedItem.totalExtra == undefined ||
                        this.editedItem.totalExtra == null ||
                        this.editedItem.totalExtra == ""
                    )
                        this.editedItem.totalExtra = 0;

                    if (this.editedItem.totalAmount == 0) {
                        this.$toast.error(
                            this.$t("error.TotalAmountShouldBeGreaterThanZero")
                        );
                        return;
                    }

                    this.editedItem.currencyGuid = this.currencyGuid;
                    this.editedItem.currencyValue = this.currencyValue;

                    this.loading = true;
                    axios
                        .post("Bills/SaveBill", this.editedItem)
                        .then(response => {
                            if (response.data.status == "Successful") {
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );

                                var routeName = "";

                                if (this.bType == "Purchase")
                                    routeName = "bill.purchaseBill";
                                else if (this.bType == "Sale")
                                    routeName = "bill.saleBill";
                                else if (this.bType == "PurchaseReturn")
                                    routeName = "bill.purchaseReturnBill";
                                else if (this.bType == "SaleReturn")
                                    routeName = "bill.saleReturnBill";
                                else if (this.bType == "InstallmentsSale")
                                    routeName = "bill.installmentsSaleBill";

                                this.$router
                                    .push({
                                        name: routeName,
                                        params: {
                                            id: response.data.data[0].billGuid
                                        },
                                        props: { vType: this.bType }
                                    })
                                    .catch(error => {
                                        error;
                                    });
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );

                            console.log(e);
                        })
                        .finally(() => {
                            this.loading = false;
                            this.refreshData();
                        });
                }
            } catch (error) {
                console.log(error);
            }
        },
        printPayment(paymentGuid) {
            axios({
                url: "Payments/PrintPayment",
                method: "GET",
                responseType: "blob",
                params: {
                    paymentGuid: paymentGuid
                }
            }).then(response => {
                var fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.setAttribute("download", "تسديد دفعة.pdf");
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        },
        printBill() {
            axios({
                url: "Bills/PrintBill",
                method: "GET",
                responseType: "blob",
                params: {
                    billGuid: this.editedItem.billGuid
                }
            }).then(response => {
                var fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.setAttribute("download", "فاتورة.pdf");
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        },
        lockBill(val) {
            try {
                this.loading = true;
                if (
                    this.editedItem.billGuid != null &&
                    this.newButtonPressed == false
                )
                    axios
                        .post("Bills/LockBill", {
                            billGuid: this.editedItem.billGuid,
                            isLock: val,
                            isAccept: val
                        })
                        .then(response => {
                            if (response.data.status == "Successful") {
                                this.refreshData();

                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                            }
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            console.log(e);
                        });
            } catch (e) {
                this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        acceptBill(val) {
            try {
                this.loading = true;
                if (
                    this.editedItem.billGuid != null &&
                    this.newButtonPressed == false
                )
                    axios
                        .post("Bills/AcceptBill", {
                            billGuid: this.editedItem.billGuid,
                            isLock: val,
                            isAccept: val
                        })
                        .then(response => {
                            if (response.data.status == "Successful") {
                                this.refreshData();

                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                            }
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            console.log(e);
                        });
            } catch (e) {
                this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        closeCustomerDialog() {
            this.customerDialog = false;
        },
        refreshCustomers() {
            this.loading = true;
            axios
                .get("Customers/Get")
                .then(response => {
                    this.customers = response.data.data;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        selectCustomer(customer) {
            try {
                this.editedItem.customerGuid = customer.customerGuid;
            } catch (error) {
                console.log(error);
            }
        },
        setIndex(item) {
            var index = this.editedItem.billBodies.indexOf(item);
            this.rowIndex = index;
        },
        getItemName(itemGuid) {
            if (itemGuid != null && itemGuid != undefined)
                return this.items.find(x => x.itemGuid == itemGuid).itemName;
            else return "";
        }
    }
};
</script>
<style>
.table-bordered {
    border: 1px solid #bdbdbd !important;
    border-collapse: collapse;
    text-align: center;
    border-radius: 10px !important;
}
.myTable {
    border: 1px solid #bdbdbd !important;
}
.myTable th,
.myTable td {
    border: 1px solid #bdbdbd !important;
    border-collapse: collapse;
    text-align: center;
    text-align-last: center;
    justify-content: center;
    padding: 0rem !important;
    overflow: hidden !important;
}
</style>
